<template>
  <div class="card-viewer">
    <div class="card-viewer-content-header">
      <div class="back-btn" @click="goBack">
        <img src="@/assets/back.png" />
      </div>
      <!-- <div class="more">更多福利</div> -->
    </div>
    <div class="card-viewer-container">
      <div class="card-viewer-content">
        <div class="card-name">{{ cardDetail.skuName || "会员卡" }}</div>
        <div class="card-shower" @click="goQrcode">
          <card :value="cardDetail"></card>
        </div>
        <div>
          <div class="card-gift-name">
            {{ "大健康合作款经典会员卡礼包" || cardDetail.manualName }}
          </div>
          <div class="card-gift-tag"><span>跨界合作</span></div>
          <div class="card-viewer-splitline" content="包含以下好礼"></div>
          <div class="card-gift-list">
            <template
              v-if="cardDetail.benefitList && cardDetail.benefitList.length"
            >
              <div
                v-for="(item, index) in cardDetail.benefitList"
                :key="index"
                class="card-gift-item"
                @click="goBenefitDetail(item)"
              >
                <div class="card-gift-item-icon">
                  <img :src="item.iconUrl" />
                </div>
                <div class="card-gift-item-content">
                  {{ item.benefitName }}
                </div>
                <!-- <div class="card-gift-item-count">
                X {{ item.benefitAmount || 0 }}
              </div> -->
                <div class="card-gift-item-tip">
                  <a-icon type="exclamation-circle" />
                </div>
              </div>
            </template>
            <div style="text-align: center; padding: 0.3rem 0" v-else>
              尚无权益
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-viewer-pay">
      <div class="card-viewer-pay-policy">
        <div class="card-viewer-pay-policy-left">
          <i
            @click="payChecked = !payChecked"
            v-if="payChecked"
            class="iconfont iconcheck"
          ></i>
          <i
            @click="payChecked = !payChecked"
            v-else
            class="iconfont iconnot-check"
          ></i>
        </div>
        <div class="card-viewer-pay-policy-right">
          点击“去支付”按钮并购买健康卡时，即代表您已阅读、理解并接受
          <span class="active-text">
            电子会员星礼包说明手册—2021年档健康卡特别款、大健康俱乐部会员礼包的规定
            健康俱乐部会员星礼包（电子版）的特别规定
          </span>
          注意：电子版会员星礼包（含会员星礼包好礼与赠品星礼卡）将在购买成功后，被同时关联至您的大健康俱乐部账户。
        </div>
      </div>
      <div class="card-viewer-pay-footer">
        <!-- <div class="card-viewer-pay-count">￥ 98</div> -->
        <a-button
          class="card-viewer-pay-submit"
          type="primary"
          shape="round"
          @click="goBindCard"
          :disabled="!payChecked"
          >去绑定</a-button
        >
      </div>
    </div>
    <Pay :visible="showPay" @onClose="showPay = false"></Pay>
    <nav-footer :shown="false"></nav-footer>
  </div>
</template>
<script>
import Card from "@/components/Card";
import Pay from "@/components/Pay";
import NavFooter from "@/components/NavFooter";
import { getCardDetail, hasCards } from "@/service";
import { isIOSBrowser } from "@/helper";
export default {
  name: "CardViewer",
  data() {
    return {
      payChecked: false,
      showPay: false,
      skuNo: null,
      cardDetail: { benefitList: [] },
    };
  },
  mounted() {
    this.skuNo = this.$route.params.skuNo || localStorage.getItem("skuNo");
    localStorage.setItem("skuNo", this.skuNo);
    this.getCardDetail();
    if (isIOSBrowser()) {
      setTimeout(() => {
        [
          ...document.querySelectorAll(".card-viewer .card-viewer-container"),
        ].forEach((v) => {
          v.style.height = "calc(100vh - 2.75rem)";
        });
      });
    }
  },
  components: { Card, Pay, NavFooter },
  computed: {
    merchantId() {
      return this.$store.state.merchantId;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goBenefitDetail(item) {
      let images = [];
      try {
        const obj = JSON.parse(item.benefitDetail);
        images = (obj || {}).imgs || [];
      } catch (error) {
        //todo
      }
      this.$router.push({
        name: "benefitDetail",
        params: { title: item.benefitName, images },
      });
    },
    async goBindCard() {
      const hasCard = await hasCards(this.merchantId);
      if (hasCard) {
        this.$router.push({ name: "bindCard" });
      } else {
        this.$router.push({
          name: "VipViewer",
          params: {
            tab: "myCard",
          },
        });
      }
    },
    goQrcode() {
      this.$eventBus.$emit("showPay", this.cardDetail.id);
    },
    async getCardDetail() {
      const params = {
        skuNo: this.skuNo,
        merchantId: this.merchantId,
      };
      const { data: cardDetail } = await getCardDetail(params);
      this.cardDetail = cardDetail || {};
    },
  },
};
</script>
<style lang="scss" scoped>
.card-viewer {
  height: 100%;
  position: relative;
  font-weight: 500;
  .card-viewer-container {
    height: calc(100vh - 1.75rem);
    position: relative;
    overflow: auto;
  }
  .card-viewer-content-header {
    padding: 0 0.15rem;
    font-weight: 600;
    line-height: 0.5rem;
    height: 0.5rem;
    width: 100%;
    color: #111;
    position: fixed;
    z-index: 99;
    .back-btn {
      width: 0.17rem;
      height: 0.17rem;
      float: left;
      overflow: hidden;
      line-height: 0;
      margin-top: 0.15rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .more {
      float: right;
      font-size: 0.12rem;
    }
  }
  .banner {
    position: absolute;
    top: 0;
    height: 2.04rem;
    width: 100%;
    background-image: url(./images/bg.png);
    @include common-bg;
  }
  .card-viewer-pay {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 1.76rem;
    padding: 0.12rem 0.3rem 0.2rem;
    box-shadow: $main-box-shadow;
    background-color: #fff;
    .card-viewer-pay-footer {
      display: flex;
      position: absolute;
      width: calc(100% - 0.6rem);
      bottom: 0.2rem;
      justify-content: flex-end;
      .card-viewer-pay-submit {
        width: 1.25rem;
        height: 0.55rem;
        line-height: 0.55rem;
        padding: 0;
        text-align: center;
        font-size: 0.16rem;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        color: #fff;
        &[disabled] {
          background-color: #d3d3d3;
        }
      }
      .card-viewer-pay-count {
        font-size: 0.2rem;
        color: $active-color;
        line-height: 0.5rem;
      }
    }
    .card-viewer-pay-policy {
      display: flex;
      .card-viewer-pay-policy-left {
        .iconfont {
          font-size: 0.18rem;
          color: $active-color;
        }
        .anticon {
          border-radius: 100%;
          overflow: hidden;
          color: #fff;
          border: 1px solid $active-color;
          &[checked] {
            color: $active-color;
            border: none;
          }
        }
      }
      .card-viewer-pay-policy-right {
        font-size: 0.09rem;
        margin-left: 0.08rem;
        height: 0.8rem;
        overflow: auto;
      }
    }
  }
  .card-viewer-content {
    width: 100%;
    background-image: url(./images/bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    overflow: hidden;
    .card-gift-list {
      padding: 0.1rem 0.3rem 0;
      .card-gift-item {
        display: flex;
        margin-bottom: 0.2rem;
        & > div {
          margin-left: 0.15rem;
        }
        .card-gift-item-icon {
          margin-left: 0;
          img {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
        .card-gift-item-content {
          // width: 1.6rem;
          flex: 1;
          @include text-overflow;
        }
        .card-gift-item-count {
          color: $active-color;
          text-align: right;
        }
        .card-gift-item-tip {
          color: $active-color;
        }
      }
    }
    .card-viewer-splitline {
      border: 0.5px solid #b9a380;
      margin: 0.18rem 0.3rem;
      position: relative;
      &::before {
        content: "" attr(content) "";
        color: #b9a380;
        width: 1.2rem;
        top: -0.08rem;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        text-align: center;
        background-color: #fff;
        font-size: 0.1rem;
      }
    }
    .card-gift-tag {
      text-align: center;
      span {
        font-size: 0.08rem;
        color: $active-color;
        border: 1px solid $active-color;
      }
    }
    .card-gift-name {
      font-size: 0.18rem;
      margin-top: 0.2rem;
      margin-bottom: 0.1rem;
      text-align: center;
    }
    .card-shower {
      width: 3.19rem;
      margin: 0 auto;
    }
    .card-name {
      font-size: 0.2rem;
      margin: 0.9rem auto 0.3rem;
      text-align: center;
      color: #25324a;
    }
  }
}
</style>
